<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div
    class="mt-5 ml-1"
  >
    <div>{{ $t('remoteConnection.title') }}</div>
    <div>
      <the-nerve-table
        id="iiotRemoteConnectionManagementTable"
        :columns="columns"
        :empty-state="$t('remoteConnection.table.emptyList')"
        :disable-pagination="true"
        :hide-footer="true"
        :is-search-enabled="false"
        :is-action-menu-enabled="false"
        :is-row-clickable="true"
        store-module="remote-connection"
        @row-clicked="params => editTable(params)"
      />
      <v-row class="mt-4 ml-2">
          <a
            v-show="canAccess('UI_REMOTE_CONN:CREATE') && versionType!=='codesys'"
            v-if="canAccess('UI_REMOTE_CONN:VIEW')"
            id="iiotRemoteConnectionManagementAddRemoteScreenButton"
            @click="showRemoteScreenDialog()"
          >
            {{ $t('remoteConnection.addRemoteScreen') }}
            <add-edit-remote-connection-screen
              :node-model="model"
              :version-model="model"
              :edit="editScreenRc"
              :version-type="versionType"
            />
          </a>
          <a
            v-show="canAccess('UI_REMOTE_CONN:CREATE')"
            v-if="canAccess('UI_REMOTE_CONN:VIEW')"
            id="iiotRemoteConnectionManagementAddRemoteTunnelButton"
            :class="versionType !== 'codesys' ? 'ml-4' : ''"
            @click="showRemoteTunnelDialog()"
          >
            {{ $t('remoteConnection.addRemoteTunnel') }}
            <add-edit-remote-connection-tunnel
              :node-model="model"
              :version-model="model"
              :edit="editTunnelRc"
              :version-type="versionType"
            />
          </a>
      </v-row>
    </div>
  </div>
</template>
<script>

import { TheNerveTable } from 'nerve-ui-components';
import AddEditRemoteConnectionTunnel from './AddEditRemoteConnectionTunnel.vue';
import AddEditRemoteConnectionScreen from './AddEditRemoteConnectionScreen.vue';

export default {
  components: {
    TheNerveTable,
    AddEditRemoteConnectionTunnel,
    AddEditRemoteConnectionScreen,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    connections: {
      type: Array,
      default: () => [],
    },
    versionType: {
      type: String,
      default: '',
    },
    versionId: {
      type: String,
      default: '',
    },
    workloadConnection: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editTunnelRc: false,
    editScreenRc: false,
  }),
  computed: {
    columns() {
      return [
        ...this.versionType === 'docker-compose' ? [
          {
            text: this.$t('remoteConnection.table.service'),
            value: 'serviceName',
            sortable: false,
          },
        ] : [],
        {
          text: this.$t('remoteConnection.table.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.table.type'),
          value: 'headerType',
          sortable: false,
        },
        {
          text: this.$t('remoteConnection.table.port'),
          value: 'port',
          sortable: false,
        },
        {
          text: this.canAccess('UI_REMOTE_CONN:DELETE') ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/remote-connection/RemoteConnectionAction.vue'),
            props: {
              iconsAction: ['Delete'],
              versionId: this.model._id,
              version: this.model,
            },
          },
        },
      ];
    },
  },
  methods: {
    async showRemoteTunnelDialog() {
      this.editTunnelRc = false;
      this.$store.dispatch('remote-connection/set_rc_tunnel_dialog', true);
      this.$store.dispatch('remote-connection/get_tunnel_rc', { id: false });
    },
    async showRemoteScreenDialog() {
      this.editScreenRc = false;
      this.$store.dispatch('remote-connection/set_rc_screen_dialog', true);
      this.$store.dispatch('remote-connection/get_screen_rc', { id: false });
    },
    editTable(params) {
      if (params.item.type === 'TUNNEL') {
        this.editTunnelRc = true;
        this.$store.dispatch('remote-connection/set_rc_tunnel_dialog', true);
        this.$store.dispatch('remote-connection/get_tunnel_rc', params);
        return;
      }
      this.editScreenRc = true;
      this.$store.dispatch('remote-connection/set_rc_screen_dialog', true);
      this.$store.dispatch('remote-connection/get_screen_rc', params);
    },
  },
};
</script>
