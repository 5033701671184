<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-form
    ref="remoteConnectionForm"
    v-model="valid"
    @keyup.enter.native="save"
  >
  <nerve-dialog
    v-model="tunnelDialog"
    :retain-focus="false"
    width="600"
    persistent
  >
    <v-card class="rc-tunnel-dialog ">
        <v-card-title>
              {{ edit ? $t('remoteConnection.dialog.editRemoteTunnel')
            : $t('remoteConnection.dialog.newRemoteTunnel') }}
        </v-card-title>
        <div>
          <v-card-text class="rc-dialog">
            <v-container class="pt-0">
              <v-row>
                <v-col>
                  <div class="mr-12 text-left">
                    {{ $t('remoteConnection.dialog.nerveParameters') }}</div>
                  <v-text-field
                    id="iiotRemoteConnectionTunnelDialogNameInput"
                    v-model="remoteConnection.name"
                    :label="$t('remoteConnection.dialog.name')"
                    :rules="rulesForName"
                    required
                  />
                  <v-select
                    id="iiotRemoteConnectionDialogLocalAcknowledgmentSelect"
                    v-model="remoteConnection.acknowledgment"
                    :label="$t('remoteConnection.dialog.localAcknowledgment')"
                    :items="localAcknowledgment"
                    attach
                  />
                  <v-select v-if="versionType === 'docker-compose'"
                    id="iiotRemoteConnectionDialogTunnelComposeServicesSelect"
                    v-model="remoteConnection.serviceName"
                    :label="$t('remoteConnection.dialog.composeServices')"
                    :rules="rulesRequiredField"
                    :items="serviceNames"
                    required
                    attach
                  />
                </v-col>
                <v-col>
                  <div class="mr-6 text-left">
                    {{ $t('remoteConnection.dialog.networkParameters') }}</div>
                  <v-text-field
                    v-if="model === 'nodes'"
                    id="iiotRemoteConnectionDialogHostnameInput"
                    v-model="remoteConnection.hostname"
                    :label="$t('remoteConnection.dialog.hostname')"
                    :rules="rulesForInputField"
                    type="input"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    v-if="versionType !== 'docker' && versionType !== 'docker-compose'
                      && model === 'workloads'"
                    id="iiotRemoteConnectionDialogHostnameInput"
                    v-model="remoteConnection.hostname"
                    :label="$t('remoteConnection.dialog.hostname')"
                    :rules="rulesForInputField"
                    type="input"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    id="iiotRemoteConnectionTunnelDialogPortInput"
                    v-model="remoteConnection.port"
                    class="number-class"
                    type="number"
                    :rules="rulesForPort"
                    :label="model === 'workloads' ? $t('remoteConnection.dialog.portOnWorkload')
                      : $t('remoteConnection.dialog.portOnNode')"
                    required
                    validate-on-blur
                  />
                  <v-text-field
                    id="iiotRemoteConnectionDialogLocalPortInput"
                    v-model="remoteConnection.localPort"
                    class="number-class"
                    :rules="rulesForPort"
                    :label="$t('remoteConnection.dialog.portOnPC')"
                    type="number"
                    required
                    validate-on-blur
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </div>
        <v-card-actions>
          <nerve-button
            id="iiotRemoteConnectionTunnelDialogCancelButton"
            :text="$t('baseForm.cancelBtn')"
            type-of-btn="cancel"
            size="normal"
            class="mr-5 ml-0 pl-2"
            autofocus
            @click-event="close()"
          />

            <nerve-button
              v-if="(!edit && !canAccess('UI_REMOTE_CONN:EDIT'))
              || (edit && canAccess('UI_REMOTE_CONN:EDIT'))
              || (!edit && canAccess('UI_REMOTE_CONN:CREATE'))"
              id="iiotRemoteConnectionTunnelDialogAddButton"
              :text="edit ? $t('baseForm.updateBtn') : $t('baseForm.add')"
              type-of-btn="action"
              size="normal"
              class="ma-0 pa-0"
              autofocus
              @click-event="save()"
            />
        </v-card-actions>
    </v-card>
  </nerve-dialog>
  </v-form>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import ConnectionTunnelModel from '@/model/node/connectionTunnel.model';
import {
  MAX_LENGTH_NAME, MIN_VALUE_OF_PORT, MAX_VALUE_OF_PORT, VALIDATION_REGEX,
} from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: { NerveButton, NerveDialog },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    versionModel: {
      type: Object,
      default: () => {},
    },
    versionType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localAcknowledgment: [
        { text: this.$t('remoteConnection.dialog.yes'), value: 'Yes', id: 'iiotRemoteConnectionDialogYesLocalAcknowledgmentOption' },
        { text: this.$t('remoteConnection.dialog.no'), value: 'No', id: 'iiotRemoteConnectionDialogNoLocalAcknowledgmentOption' },
      ],
      connectionType: [
        { text: this.$t('remoteConnection.dialog.connectionTypeVNC') },
        { text: this.$t('remoteConnection.dialog.connectionTypeSSH') },
        { text: this.$t('remoteConnection.dialog.connectionTypeRDP') },
      ],
      valid: false,
      model: '',
      workloadId: '',
      MAX_LENGTH_NAME,
      MAX_VALUE_OF_PORT,
      MIN_VALUE_OF_PORT,
      connectionModel: new ConnectionTunnelModel(),
    };
  },
  computed: {
    rulesForPort() {
      return [
        (input) => (input > MIN_VALUE_OF_PORT && input < MAX_VALUE_OF_PORT) || this.$t('remoteConnection.portNumbersMessage'),
      ];
    },
    rulesRequiredField() {
      return [
        (input) => !!input || this.$t('remoteConnection.required'),
      ];
    },
    rulesMaxLength() {
      return [
        (input) => (input.length <= MAX_LENGTH_NAME) || this.$t('remoteConnection.rulesForInputField'),
      ];
    },
    rulesForInputField() {
      return [
        (input) => !!input || this.$t('remoteConnection.required'),
        (input) => (input.length <= MAX_LENGTH_NAME) || this.$t('remoteConnection.rulesForInputField'),
      ];
    },
    rulesForName() {
      return [
        (value) => !!value || this.$t('remoteConnection.required'),
        (value) => !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES
          .test(value) || this.$t('remoteConnection.rcNameContainOnlySpaces'),
        (value) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS
          .test(value) || this.$t('remoteConnection.rcNameContainControlCharacter'),
      ];
    },
    tunnelDialog() {
      return this.$store.getters['remote-connection/rcTunnelDialog'];
    },
    remoteConnection() {
      const remoteConnection = this.$store.getters['remote-connection/getTunnelRc'];
      // Add remote-connection tunnel
      if (((this.versionType === 'docker' || this.versionType === 'docker-compose' || this.versionType === 'vm') || (this.versionType === ''))
      && remoteConnection.name === '') {
        remoteConnection.hostname = '';
        remoteConnection.port = '';
      }
      return remoteConnection;
    },
    nodeModel() {
      return this.$store.getters['nodes/getNodeData'];
    },
    remoteConnections() {
      return this.$store.getters['remote-connection/list'];
    },
    serviceNames() {
      const services = this.versionModel?.services.map((svc) => svc.serviceName);
      return services;
    },
  },
  beforeMount() {
    // eslint-disable-next-line prefer-destructuring
    this.model = window.location.pathname.split('/')[1];
    // eslint-disable-next-line prefer-destructuring
    this.workloadId = window.location.pathname.split('/')[2];
  },
  methods: {
    close() {
      this.$store.dispatch('remote-connection/set_rc_tunnel_dialog', false);
      this.$refs.remoteConnectionForm.resetValidation();
    },
    async save() {
      try {
        if (!this.$refs.remoteConnectionForm.validate()) {
          return;
        }
        if (this.model === 'nodes') {
          this.nodeModel.connection = this.remoteConnection;
          this.nodeModel.connection.type = 'TUNNEL';
          this.nodeModel.delete = false;
          await this.$store.dispatch('remote-connection/update_node', { nodeData: this.nodeModel });
        } else if (this.model === 'workloads') {
          this.remoteConnection.type = 'TUNNEL';
          await this.$store.dispatch('remote-connection/add_remote_connection', { connection: this.remoteConnection });
          this.$store.dispatch('workloads/add_edit_rc_from_workload', { connection: this.remoteConnection });
          this.$store.dispatch('workloads/form_has_changed');
        }
        this.close();
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>
<style>
  .rc-dialog {
    min-height: 250px !important;
  }
  .rc-tunnel-dialog {
    max-height: 500px !important;
    min-height: 320px !important;
  }
</style>
